<template>
    <v-btn
        class="base-button px-10"
        x-large
        depressed
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'BaseButton',
        inheritAttrs: false
    }
</script>

<style lang="scss">
    .base-button {
        border-radius: 12px !important;
        border: 1px solid #DA0A63 !important;
		// margin-bottom: 5px;
		// width: 100%;
        height: 48px;
		align-self: center;
        background: none;
        &.theme--light.v-btn.v-btn--has-bg {
            // background: inherit;
        }
        &:disabled {
            opacity: 0.5;
            border: 1px solid #DA0A63 !important;
            .v-btn__content {
                color: #DA0A63 !important;
            }
        }
        &.v-btn:not(.v-btn--round).v-size--x-large {
            height: 48px;
        }
        &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
            background: inherit;
        }

        .v-btn__content {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-align: center;
            text-transform: none;
            color: #DA0A63;
            background: none;
        }

        &.button-fill {
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
            .v-btn__content {
                color: #fff;
            }
            &:disabled {
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%) !important;
                .v-btn__content {
                    color: #fff !important;
                }
            }
        }
        &.button-white {
            background: linear-gradient(270deg, #fff 0%, #fff 100%);
        }
    }
.theme--light.v-btn.v-btn--has-bg {
    background-color: transparent !important;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
}
</style>
