export const TRIP_STATUS = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    MODERATED: 'moderated',
    NEED_EDITS: 'need_edits',
    NEED_USER: 'need_user',
    INACTIVE: 'inactive'
};

export const TRIP_STATUS_TITLE = {
    [TRIP_STATUS.DRAFT]: 'Черновик',
    [TRIP_STATUS.ACTIVE]: 'Опубликован',
    [TRIP_STATUS.MODERATED]: 'На модерации',
    [TRIP_STATUS.NEED_EDITS]: 'Нужны правки',
    [TRIP_STATUS.NEED_USER]: 'Не заполнен профиль',
    [TRIP_STATUS.INACTIVE]: 'Завершен'
};

export const BLOGGER_TRIP_STATUS = {
    DRAFT: 'draft',
    ORGANIZER_MODERATED: 'organizer_moderated',
    BLOGGER_MODERATED: 'bloger_moderated',
    MODERATED: 'moderated',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    DECLINED: 'declined',
};

export const BLOGGER_TRIP_STATUS_TITLE = {
    [BLOGGER_TRIP_STATUS.DRAFT]: 'Черновик',
    [BLOGGER_TRIP_STATUS.ORGANIZER_MODERATED]: 'Обрабатывается операторами',
    [BLOGGER_TRIP_STATUS.BLOGGER_MODERATED]: 'Ожидает подтверждения',
    [BLOGGER_TRIP_STATUS.MODERATED]: 'На модерации',
    [BLOGGER_TRIP_STATUS.ACTIVE]: 'Одобрен',
    [BLOGGER_TRIP_STATUS.COMPLETED]: 'Завершен',
    [BLOGGER_TRIP_STATUS.DECLINED]: 'Отменен',
};

export const TRIP_LEVEL = {
    BEGINNER: 'beginner',
    ADVANCED: 'advanced',
    EXPERT: 'expert'
};

export const TRIP_LEVEL_TITLE = {
    [TRIP_LEVEL.BEGINNER]: 'Стартовый',
    [TRIP_LEVEL.ADVANCED]: 'Продвинутый',
    [TRIP_LEVEL.EXPERT]: 'Экспертный'
};

export const BOOKING_STATUS = {
    STARTED: 'started',
    FILLED: 'filled',
    PAID: 'paid',
    APPROVED: 'approved',
    CANCELED: 'canceled'
};

export const BOOKING_STATUS_TITLE = {
    [BOOKING_STATUS.STARTED]: 'Начато оформление',
    [BOOKING_STATUS.FILLED]: 'Дооформлен',
    [BOOKING_STATUS.PAID]: 'Оплачен',
    [BOOKING_STATUS.APPROVED]: 'Одобрен',
    [BOOKING_STATUS.CANCELED]: 'Отменен'
};

export const BOOKING_TYPE = {
    ARRIVAL: 'arrival',
    BLOGGER_TRIP: 'bloggerTrip'
};

export const PAYMENT_STATUS = {
    PROCESSING: 'processing',
    SUCCESS: 'success',
    FAIL: 'fail',
    CANCELED: 'canceled'
};

export const PAYMENT_STATUS_TITLE = {
    [PAYMENT_STATUS.PROCESSING]: 'Обработка',
    [PAYMENT_STATUS.SUCCESS]: 'Успешно',
    [PAYMENT_STATUS.FAIL]: 'Ошибка',
    [PAYMENT_STATUS.CANCELED]: 'Отменен'
};

export const REVIEW_STATUS = {
    DRAFT: 'draft',
    MODERATED: 'moderated',
    ACTIVE: 'active',
    REJECTED: 'rejected'
};

export const REVIEW_STATUS_TITLE = {
    [REVIEW_STATUS.DRAFT]: 'Черновик',
    [REVIEW_STATUS.MODERATED]: 'На модерации',
    [REVIEW_STATUS.ACTIVE]: 'Опубликован',
    [REVIEW_STATUS.REJECTED]: 'Отклонен'
};

export const CHAT_MESSAGE_STATUS = {
    CREATED: 'created',
    DELIVERED: 'delivered',
    READED: 'readed',
    DELETED: 'deleted'
};

export const CHAT_MESSAGE_STATUS_TITLE = {
    [REVIEW_STATUS.CREATED]: 'Создано',
    [REVIEW_STATUS.DELIVERED]: 'Доставлено',
    [REVIEW_STATUS.READED]: 'Прочитано',
    [REVIEW_STATUS.DELETED]: 'Удалено'
};

export const NEWS_CONTENT_TYPE = {
    TEXT: 'text',
    PICTURE: 'picture',
    YOUTUBE: 'youtube'
};

export const DIRECTION_TYPE = {
    RUSSIA: 'russia',
    WORLD: 'world'
};

export const DIRECTION_TYPE_TITLE = {
    [DIRECTION_TYPE.RUSSIA]: 'По России',
    [DIRECTION_TYPE.WORLD]: 'Международный тур'
};

export const TRIP_TYPE = {
    TOUR_LEADER: 'tour_leader',
    HOST: 'host'
};

export const TRIP_TYPE_TITLE = {
    [TRIP_TYPE.TOUR_LEADER]: 'TOUR LEADER',
    [TRIP_TYPE.HOST]: 'HOST'
};