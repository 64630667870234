import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';

import axios from './axios';
import router from '../router';

const getadventureHosts = JSON.parse(process.env.VUE_APP_GETADVENTURE_HOSTS);
if(getadventureHosts.indexOf(window.location.host) < 0) {
    axios.get(`/front/trips/host/${ window.location.host }`)
    .then(function (response) {
        const trip = response.data;
        if(trip.yandexMetricaId && trip.yandexMetricaId !== '') {
            Vue.use(VueYandexMetrika, {
                id: trip.yandexMetricaId,
                router: router,
                env: process.env.NODE_ENV,
                options:  {
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                }
            })
        }
    });
}

// Vue.use(VueYandexMetrika, {
//     id: '',
//     env: process.env.NODE_ENV,
//     options:  {
//         clickmap: true,
//         trackLinks: true,
//         accurateTrackBounce: true,
//         webvisor: true
//    }
// })
