<template>
    <div class="desktop-header">
        <div class="desktop-header__content">
            <div class="desktop-header__logo" @click="home"></div>
            <div class="desktop-header__menu">
                <template v-if="type === 'default' && !landing">
                    <template v-for="(mkey, index) in ['mainMenuAbout', 'mainMenuBlogger', 'mainMenuOrganizer', 'mainMenuTrips']">
                        <v-menu :key="`main-menu-${index}`" bottom offset-y style="z-index: 100" v-if="menu[mkey] && menu[mkey].links">
                            <template #activator="{ on, attrs }">
                                <div class="desktop-header__menu__item" v-bind="attrs" v-on="on">
                                    {{ menu[mkey][lang] }}
                                    <img class="desktop-header__menu__item__arrow" src="../../assets/DownArrow.svg" />
                                </div>
                            </template>
                            <v-list>
                                <template v-for="link in menu[mkey].links">
                                    <v-list-item :key="`main-menu-link-${link.link}`" @click="goToLink(link.link, mkey)">
                                        <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </template>
                </template>
                <template v-if="type === 'blogger-personal-area'">
                    <template v-for="link in menu.bloggerMenu.links">
                        <div 
                            class="desktop-header__menu__item" 
                            :key="`main-menu-link-${link.link}`" 
                            v-if="user._id && user.blogger" 
                            @click="userGoToLink(link.link)">{{ link[lang] }}</div>
                    </template>
                </template>
            </div>
        </div>
        <div class="desktop-header__rigth-container">
            <div
                v-if="!user._id"
                class="desktop-header__button"
                @click="authorization">{{ { ru: 'Войти', en: 'Login' }[lang] }}</div>
            <div
                v-else-if="user.blogger"
                class="desktop-header__user-info"
                @click="goTo('blogger-audience')">
                <div :style="`background-image: url(${ imageSrc(user.avatar) })`" class="desktop-header__user-info__avatar"></div>
                <div> {{ user.firstname }} {{ user.lastname }} </div>
            </div>
            <div
                v-else-if="user._id"
                class="desktop-header__user-info"
                @click="goTo('user-profile')">
                <div :style="`background-image: url(${ imageSrc(user.avatar) })`" class="desktop-header__user-info__avatar"></div>
                <div> {{ user.firstname }} {{ user.lastname }} </div>
            </div>
            <v-menu 
                bottom 
                offset-y
                style="z-index: 100"
            >
                <template #activator="{ on, attrs }">
                    <div
                        class="desktop-header__category"
                        v-bind="attrs" 
                        v-on="on"
                    >
                        <img src="../../assets/Category.svg" class="desktop-header__category__icon" />
                    </div>
                </template>
                <v-list v-if="user._id">
                    <v-list-item @click="userGoTo('user-profile')">
                        <v-list-item-title>{{ { ru: 'Профиль', en: 'Profile' }[lang]  }}</v-list-item-title>
                    </v-list-item>
                    <template v-for="link in menu.organizerMenu.links">
                        <v-list-item :key="`main-menu-link-${link.link}`" v-if="user.organizer" @click="userGoToLink(link.link)">
                            <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <template v-for="link in menu.hiddenMenu.links">
                        <v-list-item :key="`main-menu-link-${link.link}`" @click="userGoToLink(link.link)">
                            <v-list-item-title>{{ link[lang] }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list-item @click="logout">
                        <v-list-item-title>{{ { ru: 'Выйти', en: 'Logout' }[lang]  }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
    
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';
    import trips from '../../store/modules/trips';

    export default {
        name: 'Header',
        components: {},
        props: {
            type: {
                type: String,
                default: 'default'
            },
        },
        data: () => ({
            imageSrc,
            landing: false,
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('pageTemplates', {
                menu: state => state.headerMenu
            }),
            ...mapState('auth', ['user']),
        },
        mounted() {
            if(process.env.VUE_APP_SITE_TYPE === 'landing') {
                this.landing = true
            }
        },
        methods: {
            async home() {
                await this.$router.push({ name: 'home' });
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async goToLink(link) {
                await this.$router.push(link);
            },
            async userGoTo(name) {
                if(this.user._id) {
                    await this.$router.push({ name });
                } else {
                    this.authorization()
                }
            },
            async userGoToLink(link) {
                if(this.user._id) {
                    await this.$router.push(link);
                } else {
                    this.authorization()
                }
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
                this.$root.$once('authorized', async () => {
                    if(this.user.blogger) {
                        await this.$router.push({ name: 'blogger-audience' });
                    }  
                });
            },
            async logout() {
                if(this.user._id) {
                    await store.dispatch('auth/logout');
                    store.commit('trips/CLEAR_ENTITIES');
                    await store.dispatch('trips/fetch', { filter: { type: TRIP_TYPE.TOUR_LEADER, status: TRIP_STATUS.ACTIVE } });
                    await this.$router.push({ name: 'home' });
                }
            },
        },
    }
</script>


<style lang="scss">
    .desktop-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 94px;
        // border-bottom: 1px solid #E5E6E9;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        margin: 0 32px;

        @media all and (min-width: 1464px) {
            margin: auto;
            max-width: 1400px;
        }

        &__logo {
            width: 200px;
            height: 25px;
            margin-right: 60px;
            background: url(../../assets/Main-logo.svg) center center no-repeat;
            background-size: contain;
            cursor: pointer
        }

        &__content {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        &__link {
            display: flex;
            justify-content: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: #A1A4B1;
            margin-right: 22px;
            cursor: pointer;

            &:last-of-type {
                margin-right: 0;
            }
            
            &__down-arrow {
                width: 10px;
                height: 4px;
                margin-left: 11px;
            }
        }

        &__menu {
            display: flex;
            flex-direction: row;
            margin-right: 60px;
            @media all and (max-width: 1160px) {
                margin-right: 20px;
            }
            &__item {
                margin-left: 32px;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #686F88 !important;
                cursor: pointer;
                @media all and (max-width: 1160px) {
                    margin-left: 10px;
                }
                &:first-of-type {
                    margin-left: 0;
                }
                
                &__arrow {
                    margin-left: 4px;
                }
            }
        }

        &__rigth-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        &__user-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            &__avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                margin-right: 10px;
            }
        }
        &__button {
            display: flex;
            justify-content: center;
            padding: 10px 20px;
            border: 1px solid #F1F3F5;
            box-sizing: border-box;
            border-radius: 12px;
            cursor: pointer
        }
        &__category {
            width: 40px;
            height: 40px;
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            cursor: pointer;
            &__icon {
                width: 16px;
                height: 16px;
            }
        }
    }
</style>