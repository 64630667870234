export default {
    namespaced: true,

    state: {
        lang: process.env.VUE_APP_SITE_LANGUAGE,
    },

    mutations: {
    },

    actions: {
    },

    getters: {}
}