<template>
    <v-dialog 
        v-model="shown"
        max-width="400px"
        v-if="translates && translates[langUrl]"
    >
        <div class="popup-login" v-if="translates && translates[langUrl]">
            <div class="popup-login__top">
                <div v-if="showConfirm === false" class="popup-login__top__title">{{ translates[langUrl].pageTitle_enter[lang] }}</div>
                <div v-if="showConfirm === true" class="popup-login__top__title">{{ translates[langUrl].pageTitle_confirmPhone[lang] }}</div>
                <div class="popup-login__top__close" @click="close"></div>
            </div>
            <div v-if="showConfirm === true" class="popup-login__number">{{ translates[langUrl].nuber_part_1[lang] }} {{ phone }} {{ translates[langUrl].nuber_part_2[lang] }}</div>
            <div v-if="showConfirm === false" class="popup-login__input">
                <ValidationObserver ref="validator_1" slim>
                    <!-- <VuePhoneNumberInput
                        v-model="phone"
                        :default-country-code="countryCode()"
                        :countries-height="14"
                    /> -->
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <LabelTextField
                            :label="translates[langUrl].label_phone[lang]"
                            :error-messages="errors[0]"
                            type="tel"
                            autocomplete="off"
                            v-model="phone"
                            @keyup.enter="login"
                        />
                    </ValidationProvider>
                </ValidationObserver>
                <BaseButton 
                    class="button-fill" 
                    style="width: 100%"
                    @click="login">{{ translates[langUrl].button_next[lang] }}</BaseButton>
            </div>
            <div v-if="showConfirm === true" class="popup-login__input">
                <ValidationObserver ref="validator_2" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required|code">
                        <LabelTextField
                            v-model="code"
                            pattern="\d*"
                            :label="translates[langUrl].label_code[lang]"
                            counter="4"
                            :error-messages="errors[0]"
                            @keyup.enter="confirm"
                            autocomplete="off"
                        />
                    </ValidationProvider>
                    <div v-if="secondsLeft" class="popup-login__send-code">{{ translates[langUrl].button_codeRepeat_part_1[lang] }} <span class="popup-login__send-code__timer">{{ translates[langUrl].button_codeRepeat_part_2[lang] }} {{ prettyCounter }}</span></div>
                    <div
                        v-else
                        class="popup-login__send-code_active"
                        @click="phoneRepeatSubmit">{{ translates[langUrl].button_codeRepeat_part_1[lang] }}</div>
                    <div class="popup-login__buttons">
                        <BaseButton
                            style="width: 100%"
                            class="button-fill"
                            @click="confirm">{{ translates[langUrl].button_confirmPhone[lang] }}</BaseButton>
                    </div></ValidationObserver>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    import LabelTextField from '../../components/common/LabelTextField';
    import BaseButton from '../../components/common/BaseButton';

    export default {
        name: 'PopupLogin',
        components: {
            ValidationProvider,
            ValidationObserver,
            LabelTextField,
            BaseButton,
            VuePhoneNumberInput
        },
        data: () => ({
            phoneCode : null,
            phone: '',
            code: '',
            showConfirm: false,
            shown: false,
            secondsLeft: 0,
            intervalId: undefined,
            langUrl: 'authorization'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            prettyCounter() {
                if (this.secondsLeft < 1) {
                    return '';
                }

                const minutes = Math.floor(this.secondsLeft / 60);
                const seconds = this.secondsLeft - minutes * 60;

                return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
            },
        },
        mounted() {
            let this_ = this;
            this_.$root.$on('popupLoginShow', async () => {
                this_.shown = true;
            });
            this_.$root.$on('popupLoginHide', () => {
                this_.shown = false;
            });
        },
        methods: {
            countryCode() {
                return this.lang === 'en' ? "US" : "RU";
            },
            async login() {
                const valid = await this.$refs['validator_1'].validate();
                if(valid) {
                    await store.dispatch('auth/login', { phone: '+' + this.phone.replace(/[^\d]/g, '') });
                    this.showConfirm = true;
                }
                await this.startTimer();
            },
            async phoneRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/login', { phone: '+' + this.phone.replace(/[^\d]/g, '') });
            },
            isUserProfile() {
                if(this.user.firstname !== '' && this.user.lastname !== '' && this.user.avatar !== '') {
                    this.$router.push({ name: 'home' });
                } else {
                    this.$router.push({ name: 'user-profile-edit' });
                }
            },
            async confirm() {
                const valid = await this.$refs['validator_2'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    this.close();
                    this.$root.$emit('authorized');
                }
            },
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.secondsLeft = 120;

                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
            async back() {
                this.showConfirm = false
            },
            close() {
                this.shown = false,
                this.code = '',
                this.phone = '',
                this.showConfirm = false;
            },
        }
    }
</script>

<style lang="scss">
.popup-login {
    max-height: 400px;
    padding: 15px 20px 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    background: #FFFFFF;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 12px;
    &__open-button {
        display: flex;
        justify-content: center;
        padding: 10px 20px;
        border: 1px solid #F1F3F5;
        box-sizing: border-box;
        border-radius: 12px;
        background: none;
        cursor: pointer
    }
    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        padding-bottom: 13px;
        border-bottom: 1px solid #EBEBEB;
        &__title {
            display: flex;
            flex-direction: column;
            width: 280px;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #273155;
        }
        &__close {
            width: 15px;
            height: 15px;
            background: url(../../assets/PlusGrey.svg);
            transform: rotate(45deg);
            margin-top: 5px;
            cursor: pointer;
        }
    }
    &__number {
        padding-top: 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;
        opacity: 0.5;
        // padding-bottom: 10px;
    }
    &__input {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    }
    &__send-code {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin: 7px auto 20px 10px;
        &__timer {
            opacity: 0.5;
        }
        &_active {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            text-decoration: underline;
            // opacity: 0.5;
            color: #3A4256;
            margin: 7px auto 20px 10px;
            cursor: pointer;
        }
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 15px
    }
    .vue-phone-number-input {
        height: 56px;
        margin-bottom: 15px;
    }
}
</style>