import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        chats: [],
        chat: {},
        messages: []
    },

    mutations: {
        SET_CHATS(state, payload) {
            state.chats = payload;
        },
        SET_CHAT(state, payload) {
            state.chat = payload;
        },
        SET_MESSAGES(state, payload) {
            state.messages = payload;
        },
        CLEAR_ENTITY(state) {
            state.chats = [];
            state.chat = {};
            state.messages = [];
        }
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/chats', { params: { filter, sorting } });
            commit('SET_CHATS', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/chats/${ id }`);
            commit('SET_CHAT', data);
        },
        async create({ commit }, { trip, users }) {
            const { data } = await axios.post('/front/chats/', { trip, users });
            return data;
        },
        async fetchMessages({ commit }, { chatId }) {
            const { data } = await axios.get(`/front/chats/${ chatId }/messages`);
            commit('SET_MESSAGES', data);
            return data;
        },
        async sendMessage({ dispatch }, { chatId, userId, content, file, replyMessage }) {
            await axios.post(`/front/chats/${ chatId }/messages`, {
                chat: chatId,
                user: userId,
                text: content,
            });
            dispatch('fetchMessages', { chatId });
        },
        async editMessage({ dispatch }, { chatId, messageId, content }) {
            await axios.put(`/front/chats/${ chatId }/messages/${ messageId }`, {
                text: content,
            });
            dispatch('fetchMessages', { chatId });
        },
        async deleteMessage({ dispatch }, { chatId, messageId, content }) {
            await axios.delete(`/front/chats/${ chatId }/messages/${ messageId }`);
            dispatch('fetchMessages', { chatId });
        }
    },

    getters: { }
}
