<template>
    <v-app>
        <component :is="layout">
        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        </component>
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';

    import DesktopHeader from './components/desktop/Header.vue'
    import Header from './components/mobile/Header.vue'
    import Footer from './components/desktop/Footer.vue';
    import MobileFooter from './components/mobile/Footer.vue';
    import PopupLogin from './components/common/PopupLogin.vue';


    export default {
        name: 'GetAdventure',
        metaInfo: {
            title: 'GetAdventures',
            titleTemplate: '%s | GetAdventures'
        },
        components: {
            DesktopHeader,
            Header,
            Footer,
            MobileFooter,
            PopupLogin
        },
        data: () => ({
            isDesktop: false,
        }),
        computed: {
            ...mapState('auth', ['user']),
            layout() {
                const layoutName = this.$route.meta.layout || 'LayoutDefault';
                return () => import(`@/layouts/${layoutName}.vue`)
            },
            isMarketplace() {
                return process.env.VUE_APP_SITE_TYPE === 'marketplace';
            }
        },
        async mounted() {
            await store.dispatch('translates/fetch');
            await store.dispatch('dictionaries/fetch');
            await store.dispatch('pageTemplates/fetch');
            if(!this.user._id) {
                try {
                    await store.dispatch('auth/fetch');
                } catch(e) {}
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },

        methods: {
            onResize() {
                this.isDesktop = !isMobile();
            }
        }
    }
</script>

<style lang="scss">
.v-main__wrap {
    // padding-bottom: 76px;
    // background:  #F8F7FA;
}
</style>
