import { required, email, max, ext, size, image } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

const LANG = process.env.VUE_APP_SITE_LANGUAGE;

extend('phone', {
    validate(value, args) {
        return /^(\+7) (\d{3}) (\d{3}) (\d{2}) (\d{2})$/.test(value);
    },
    message: { ru: 'Недопустимый формат телефона', en: 'Invalid phone number format' }[LANG]
});

extend('code', {
    validate(value, args) {
        return /^(\d{4})$/.test(value);
    },
    message: { ru: 'Недопустимый формат кода', en: 'Invalid code format' }[LANG]
});

extend('social', {
    validate(value, args) {
        return /^([\w\_\-\@\.]+)$/g.test(value);
    },
    message: { ru: 'Поле может содержать только латинские буквы, цифры и знаки @_-.', en: 'Field can contain only latin letters, numbers and signs @_-.' }[LANG]
});

extend('required', {
    ...required,
    message: { ru: 'Пожалуйста, заполните поле', en: 'Fill the field please' }[LANG]
});

extend('max', {
    ...max,
    message: { ru: 'Колличество символов не должно превышать {length}', en: "The number of characters mustn't exceed {length}" }[LANG]
});

extend('email', {
    ...email,
    message: { ru: 'Недопустимый формат email', en: 'Invalid email format' }[LANG]
});

extend('ext', {
    ...ext,
    message: { ru: 'Недопустимое расширение файла', en: 'Invalid file extension' }[LANG]
});

extend('size', {
    ...size,
    message: (field, { size }) => {
        return { ru: `Размер файла не должен превышать ${ size / 1024 } МБ`, en: `File size mustn't exceed ${ size / 1024 } MB` }[LANG]
    }
});
