import axios from '../../plugins/axios';
import moment from 'moment';

export default {
    namespaced: true,

    state: {
        dictionaries: {
            directionsRussian: [],
            directionsWorld: [],
            directionsAdditional: [],
            directions: [],
            months: [],
            durations: [],
            activities: [],
            comforts: [],
            tripsCount: [],
        },
        entities: [],
        entitiesCount: 0,
        entity: {},
        summary: {}
    },

    mutations: {
        SET_DICTIONARIES(state, payload) {
            for(const code in payload) {
                state.dictionaries[code] = payload[code];
            }
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        SET_SUMMARY(state, payload) {
            state.summary = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async dictionaries({ commit }) {
            const { data } = await axios.get('/common/quiz-dictionaries');
            commit('SET_DICTIONARIES', data);
            return data;
        },
        async fetch({ commit }, { filter = {}, sorting = null, limit = null, page = null }) {
            const { data } = await axios.get('/front/quizes', { params: { filter, sorting, limit, page } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async summary({ commit }, { qualified = false }) {
            const { data } = await axios.get('/front/quizes/summary', { params: { qualified } });
            commit('SET_SUMMARY', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/quizes/${ id }`);
            data.date = moment(data.date).format('YYYY-MM-DD');
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            await axios.post('/front/quizes', state.entity);
        },
    },

    getters: {}
}
