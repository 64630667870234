import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {
            trip: null,
            start: null,
            finish: null,
            price: 0,
            places: 0,
            currency: process.env.VUE_APP_DEFAULT_CURRENCY,
            mainTraveler: {
                birthday: null
            },
            additionalTravelers: []
        }
    },
    mutations: {
        CLEAR_ENTITIES(state) {
            state.entities = [];
            state.entitiesCount = 0;
        },
        CLEAR_ENTITY(state) {
            state.entity = {
                trip: null,
                start: null,
                finish: null,
                price: 0,
                places: 0,
                currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                mainTraveler: {
                    birthday: null
                },
                additionalTravelers: []
            };
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = {
                ...state.entity,
                ...payload
            };
        },
        // SET_ENTITY_VALUE(state, { prop, value }) {
        //     if(typeof prop !== 'undefined') {
        //         state.entity[prop] = value;
        //     }
        // },
        SET_ENTITY_VALUE(state, { prop_1, prop_2, prop_3, prop_4, value }) {
            if(typeof prop_4 !== 'undefined') {
                state.entity[prop_1][prop_2][prop_3][prop_4] = value;
            } else if(typeof prop_3 !== 'undefined') {
                state.entity[prop_1][prop_2][prop_3] = value;
            } else if(typeof prop_2 !== 'undefined') {
                state.entity[prop_1][prop_2] = value;
            } else if(typeof prop_1 !== 'undefined') {
                state.entity[prop_1] = value;
            } else {
                state.entity = value;
            }
        },
    },
    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/bookings', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async fetchOrganizer({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/front/bookings/organizer', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/bookings/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/front/bookings/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/front/bookings', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/bookings/${ id }`);
        },
    },

    getters: {}
}
